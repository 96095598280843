<template>
  <div>
    <v-card flat class="mt-4">
      <v-toolbar dense elevation="0">
        <v-text-field
          v-model="search"
          label="Search"
          style="max-width: 220px"
          dense
          append-icon="mdi-magnify"
          class="mt-3"
        ></v-text-field>
      </v-toolbar>
      <v-card-text>
        <v-data-table
          :height="height"
          class="overflow-y-auto overflow mt-n5"
          dense
          :search="search"
          :headers="tabledata"
          :options.sync="pagination"
          @update:options="get_leave()"
          :items="exporttable"
          :items-per-page="10"
          :loading="loadingreport"
          :fixed-header="fixed"
          loading-text="Loading... Please wait"
          :footer-props="{
            'items-per-page-options': [10, 15, 20, 25],
          }"
        >
          <template v-slot:no-data>
            <v-alert :value="true" class="gradient-bg2 white--text">
              No Leave Applied.
            </v-alert>
          </template>
          <template v-slot:no-results>
            <v-alert :value="true" class="gradient-bg2 white--text">
              No records found!
            </v-alert>
          </template>
          <template v-slot:[`item.leave_type`]="{ item }">
            <span>{{ `${item.leave_type}  - ${item.total_days_leave}` }} </span>
          </template>
          <template v-slot:[`item.leave_from_date`]="{ item }">
            <span v-text="get_formate(item.leave_from_date)"></span>
            <br />
            <span v-text="get_formate(item.leave_to_date)"></span>
          </template>
          <template v-slot:[`item.leave_to_date`]="{ item }">
            <span v-text="get_formate(item.leave_to_date)"></span>
          </template>

          <template v-slot:[`item.leave_applied_on`]="{ item }">
            <span v-text="get_date(item.leave_applied_on)"></span>
          </template>
          <template v-slot:[`item.approved_on`]="{ item }">
            <span v-if="item.approved_on == undefined">-</span>
            <span v-text="get_date(item.approved_on)" v-else></span><br />
            <span v-if="item.approved_by == undefined">-</span>
            <span v-else>{{ item.approved_by }}</span>
          </template>
          <template v-slot:[`item.approved_by`]="{ item }">
            <span v-if="item.approved_by == undefined">-</span>
            <span v-else>{{ item.approved_by }}</span>
          </template>
          <template v-slot:[`item.actionsstatus`]="{ item }">
            <div v-if="item.leave_status == 'APPROVED'" class="green--text">
              Approved
            </div>
            <div
              v-else-if="item.leave_status == 'PENDING'"
              class="orange--text"
            >
              Pending
            </div>
            <div v-else-if="item.leave_status == 'REJECTED'" class="red--text">
              Rejected
            </div>
            <div
              v-else-if="item.leave_status == 'WITHDRAWN'"
              class="brown--text"
            >
              Withdrawn
            </div>
            <div
              v-else-if="item.leave_status == 'CANCELLATION_PENDING'"
              class="orange--text"
            >
              Cancellation Pending
            </div>
            <div
              v-else-if="item.leave_status == 'CANCELLATION_APPROVED'"
              class="green--text"
            >
              Cancellation Approved
            </div>
            <div
              v-else-if="item.leave_status == 'CANCELLATION_REJECTED'"
              class="red--text"
            >
              Cancellation Rejected
            </div>
          </template>

          <template v-slot:[`item.leave_status`]="{ item }">
            <v-tooltip bottom color="primary">
              <template v-slot:activator="{ on }">
                <v-icon
                  small
                  v-on="on"
                  depressed
                  style="color: green"
                  medium
                  v-show="item.leave_status == 'APPROVED'"
                >
                  mdi-account-check
                </v-icon>
              </template>
              <span class="white--text">APPROVED</span>
            </v-tooltip>
            <v-tooltip bottom color="primary">
              <template v-slot:activator="{ on }">
                <v-icon
                  small
                  v-on="on"
                  depressed
                  style="color: red"
                  medium
                  v-show="item.leave_status == 'CANCELLATION_REJECTED'"
                >
                  mdi-account-cancel
                </v-icon>
              </template>
              <span class="white--text">Cancellation Rejected</span>
            </v-tooltip>
            <v-tooltip bottom color="primary">
              <template v-slot:activator="{ on }">
                <v-icon
                  small
                  v-on="on"
                  v-show="item.leave_status == 'PENDING'"
                  depressed
                  style="color: orange"
                  @click="Approve(item)"
                  mediumapproved_on
                >
                  mdi-account-alert
                </v-icon>
                <!--  -->
              </template>
              <span class="white--text">PENDING </span>
            </v-tooltip>
            <v-tooltip bottom color="primary">
              <template v-slot:activator="{ on }">
                <v-icon
                  small
                  v-on="on"
                  depressed
                  style="color: red"
                  medium
                  v-show="item.leave_status == 'REJECTED'"
                >
                  mdi-account-cancel
                </v-icon>
              </template>
              <span class="white--text">REJECTED </span>
            </v-tooltip>
            <v-tooltip bottom color="primary">
              <template v-slot:activator="{ on }">
                <v-icon
                  small
                  v-on="on"
                  depressed
                  style="color: red"
                  medium
                  v-show="item.leave_status == 'CANCELED'"
                >
                  mdi-cancel
                </v-icon>
              </template>
              <span class="white--text">CANCELED</span>
            </v-tooltip>
            <v-tooltip bottom color="primary">
              <template v-slot:activator="{ on }">
                <div v-show="item.leave_status == 'WITHDRAWN'" v-on="on">-</div>
              </template>
              <span class="white--text">WITHDRAWN</span>
            </v-tooltip>
            <v-tooltip bottom color="primary">
              <template v-slot:activator="{ on }">
                <v-icon
                  small
                  v-on="on"
                  v-show="item.leave_status == 'CANCELLATION_PENDING'"
                  depressed
                  style="color: orange"
                  @click="Approve(item)"
                  mediumapproved_on
                >
                  mdi-account-alert
                </v-icon>
              </template>
              <span class="white--text">  Cancellation Pending</span>
            </v-tooltip>
            <v-tooltip bottom color="primary">
              <template v-slot:activator="{ on }">
                <v-icon
                  small
                  v-on="on"
                  depressed
                  style="color: green"
                  medium
                  v-show="item.leave_status == 'CANCELLATION_APPROVED'"
                >
                  mdi-account-check
                </v-icon>
              </template>
              <span class="white--text">Approved Cancellation</span>
            </v-tooltip>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip bottom color="primary">
              <template v-slot:activator="{ on }">
                <v-icon
                  small
                  v-on="on"
                  depressed
                  color="primary"
                  medium
                  @click="commentsopen(item)"
                >
                  mdi-information-variant-circle-outline
                </v-icon>
              </template>
              <span class="white--text">Comments</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <SnackBar :SnackBarComponent="SnackBarComponent" />
    <div v-if="componentcheck == 1">
      <ApproveLeave
        :approvaldta="approvaldta"
        :leaveApproveReject="leaveApproveReject"
        @clicked="leaveApproveReject = false"
        v-on:errorMsg="errorPop"
        v-on:successMsg="sucessPop"
      />
    </div>
    <div v-if="componentcheck == 2">
      <LeaveComments
        :comLeave="comLeave"
        @clicked="comLeave = false"
        :approvaldta="approvaldta"
      />
    </div>
    <Overlay :overlay="overlay" />
  </div>
</template>

<script>
import LeaveComments from "../../components/Dialogs/LeaveComments.vue";
import { API, graphqlOperation } from "aws-amplify";
import { list_leave_approvals } from "@/graphql/queries.js";
import SnackBar from "@/components/SnackBar.vue";
import ApproveLeave from "../../components/Dialogs/ApproveLeave.vue";
import Overlay from "@/components/Overlay.vue";
export default {
  components: {
    SnackBar,
    ApproveLeave,
    Overlay,
    LeaveComments,
  },
  data() {
    return {
      height: 0,
      componentcheck: 0,
      comLeave: false,
      search: "",
      approvaldta: {},
      leaveApproveReject: false,
      exporttable: [],
      loadingreport: false,
      overlay: false,
      SnackBarComponent: {},
      fixed: true,
      tabledata: [
        { text: "Members", fixed: true, value: "user_name" },
        { text: "Leave From / To", fixed: true, value: "leave_from_date" },
        // { text: "Leave To", fixed: true, value: "leave_to_date" },
        { text: "Type /Days", fixed: true, value: "leave_type" },
        { text: "Applied On", fixed: true, value: "leave_applied_on" },

        // { text: "Total Days", fixed: true, value: "total_days_leave" },
        { text: "Approved On", fixed: true, value: "approved_on" },
        // { text: "Approved By", fixed: true, value: "approved_by" },
        { text: "Comments", fixed: true, value: "actions" },
        { text: "Status", fixed: true, value: "actionsstatus" },
        {
          text: "Actions",
          fixed: true,
          value: "leave_status",
          sortable: false,
        },
      ],
      pagination: {
        itemsPerPage: 10,
        page: 1,
      },
      nextToken: null,
    };
  },
  created() {
    this.height = window.innerHeight - 190;
    this.get_leave();
  },
  methods: {
    commentsopen(item) {
      this.approvaldta = item;
      this.componentcheck = 2;
      this.comLeave = true;
    },
    get_date(timestamp) {
      const dateObj = new Date(timestamp * 1000); // Convert to milliseconds by multiplying with 1000
      const year = dateObj.getFullYear();
      const month = String(dateObj.getMonth() + 1).padStart(2, "0");
      const day = String(dateObj.getDate()).padStart(2, "0");
      return `${day}-${month}-${year}`;
    },
    get_formate(inputDate) {
      const parts = inputDate.split("-");
      const day = parts[2];
      const month = parts[1];
      const year = parts[0];

      return `${day}-${month}-${year}`;
    },
    async get_leave() {
      this.overlay = true;
      this.loadingreport = true;
      try {
        let result = await API.graphql(
          graphqlOperation(list_leave_approvals, {
            input: {
              limit: 50,
              organisation_id:
                this.$store.getters.get_org_details[0].organisation_id,
              nextToken: this.nextToken,
            },
          })
        );

        var respnse = result.data.list_leave_approvals;
        this.nextToken = respnse.nextToken;
        // console.log(respnse.nextToken);
        if (respnse.Status == "SUCCESS") {
          let array = this.exporttable.concat(respnse.data);
          this.exporttable = array;
          const uniqueArrayOfObjects = this.exporttable.filter(
            (obj, index, self) =>
              index === self.findIndex((o) => o.leave_id === obj.leave_id)
          );
          this.exporttable = uniqueArrayOfObjects;
          // this.exporttable = respnse.data;
          // console.log( this.exporttable);
          this.loadingreport = false;
          this.overlay = false;
        }
        // console.log(result);
        this.loadingreport = false;
        this.overlay = false;
        // window.open(item);
      } catch (error) {
        console.log(error);
        this.loadingreport = false;
        this.overlay = false;
        this.isLoading = false;
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: error.errors[0].message,
        };
      }
    },
    Approve(item) {
      this.approvaldta = item;
      this.componentcheck = 1;
      this.leaveApproveReject = true;
    },
    errorPop(val) {
      this.SnackBarComponent = {
        SnackbarVmodel: true,
        SnackbarColor: "red",
        Top: true,
        SnackbarText: val,
      };
    },
    sucessPop(val) {
      this.leaveApproveReject = false;
      this.SnackBarComponent = {
        SnackbarVmodel: true,
        SnackbarColor: "green",
        Top: true,
        SnackbarText: val,
      };
      this.exporttable = [];
      this.get_leave();
    },
  },
};
</script>

<style>
</style>