<template>
  <div>
    <v-dialog v-model="leaveApproveReject" persistent width="450px">
      <v-card class="overflow-hidden">
        <v-toolbar dense class="gradient-bg elevation-0">
          <v-toolbar-title class="white--text">Leave Approval</v-toolbar-title
          ><v-spacer />
          <v-icon @click="close_dialog()" class="white--text">mdi-close</v-icon>
        </v-toolbar>
        <v-card-text
          align="center"
          style="font-weight: bold; text-align: justify"
        >
          <div
            class="mt-5"
            v-if="approvaldta.leave_status != 'CANCELLATION_PENDING'"
          >
            Would you like to Approve / Reject leave for
            {{ approvaldta.user_name }} ?
          </div>
          <div v-else class="mt-5">
            Would you like to cancel the {{ approvaldta.user_name }} leave ?
          </div>
          <v-form ref="form">
            <v-textarea
              v-model="leavereason"
              height="100px"
              dense
              label="Comments"
              class="mt-5"
              :rules="[
                (v) => !!v.trim() || 'Required',
                (v) =>
                  v.length <= 100 || 'Comments  must be under 100 characters',
              ]"
              outlined
            />
          </v-form>
        </v-card-text>
        <div class="d-flex justify-center mb-3">
          <v-card-actions class="mt-n5">
            <v-btn
              color="primary"
              class="white--text"
              @click="reject_datacorrection()"
              :loading="rejectload"
              small
              >Reject</v-btn
            >
            <v-btn
              color="primary"
              :loading="approveload"
              class="white--text"
              @click="approvedata()"
              small
              >Approve</v-btn
            >
          </v-card-actions>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>
  
  <script>
import { API, graphqlOperation } from "aws-amplify";
import { approveRejectLeave } from "@/graphql/mutations.js";
export default {
  props: {
    leaveApproveReject: Boolean,
    approvaldta: Object,
  },
  data: () => ({
    leavereason: "",
    rejectload:false,
    approveload:false,
  }),
  methods: {
    async reject_datacorrection() {
      if (this.$refs.form.validate()) {
        this.rejectload=true;
        try {
          let result = await API.graphql(
            graphqlOperation(approveRejectLeave, {
              input: {
                leave_id: this.approvaldta.leave_id,
                leave_status:
                  this.approvaldta.leave_status == "CANCELLATION_PENDING"
                    ? "CANCELLATION_REJECTED"
                    : "REJECTED",
                user_email_id:
                  this.$store.getters.get_org_user_details[0].user_email_id,
                approvals_comment: this.leavereason,
              },
            })
          );
          // console.log(result.data.approveRejectLeave);
          this.rejectload=false;
          var respnse = result.data.approveRejectLeave;
          if (respnse.Status == "ERROR") {
            this.rejectload=false;
            this.$emit("errorMsg", respnse.Message);
          } else {
            this.rejectload=false;
            this.$refs.form.reset();
            this.$refs.form.resetValidation();
            this.$emit("successMsg", respnse.Message);
          }
        } catch (error) {
          this.rejectload=false;
          this.$emit("errorMsg", error.errors[0].message);
          console.log(error);
        }
      }
    },
    async approvedata() {
      if (this.$refs.form.validate()) {
        this.approveload=true;
        try {
          let result = await API.graphql(
            graphqlOperation(approveRejectLeave, {
              input: {
                leave_id: this.approvaldta.leave_id,
                leave_status:
                  this.approvaldta.leave_status == "CANCELLATION_PENDING"
                    ? "CANCELLATION_APPROVED"
                    : "APPROVED",
                user_email_id:
                  this.$store.getters.get_org_user_details[0].user_email_id,
                approvals_comment: this.leavereason,
              },
            })
          );
          this.approveload=false;
          var respnse = result.data.approveRejectLeave;
          if (respnse.Status == "ERROR") {
            this.approveload=false;
            this.$emit("errorMsg", respnse.Message);
          } else {
            this.approveload=false;
            this.$refs.form.reset();
            this.$refs.form.resetValidation();
            this.$emit("successMsg", respnse.Message);
          }
        } catch (error) {
          this.approveload=false;
          this.$emit("errorMsg", error.errors[0].message);
          console.log(error);
        }
      }
    },
    close_dialog() {
      this.$refs.form.reset();
      this.$refs.form.resetValidation();
      this.$emit("clicked", 0);
    },
  },
};
</script>
  
  <style>
</style>