var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"mt-4",attrs:{"flat":""}},[_c('v-toolbar',{attrs:{"dense":"","elevation":"0"}},[_c('v-text-field',{staticClass:"mt-3",staticStyle:{"max-width":"220px"},attrs:{"label":"Search","dense":"","append-icon":"mdi-magnify"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-card-text',[_c('v-data-table',{staticClass:"overflow-y-auto overflow mt-n5",attrs:{"height":_vm.height,"dense":"","search":_vm.search,"headers":_vm.tabledata,"options":_vm.pagination,"items":_vm.exporttable,"items-per-page":10,"loading":_vm.loadingreport,"fixed-header":_vm.fixed,"loading-text":"Loading... Please wait","footer-props":{
          'items-per-page-options': [10, 15, 20, 25],
        }},on:{"update:options":[function($event){_vm.pagination=$event},function($event){return _vm.get_leave()}]},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-alert',{staticClass:"gradient-bg2 white--text",attrs:{"value":true}},[_vm._v(" No Leave Applied. ")])]},proxy:true},{key:"no-results",fn:function(){return [_c('v-alert',{staticClass:"gradient-bg2 white--text",attrs:{"value":true}},[_vm._v(" No records found! ")])]},proxy:true},{key:"item.leave_type",fn:function(ref){
        var item = ref.item;
return [_c('span',[_vm._v(_vm._s(((item.leave_type) + " - " + (item.total_days_leave)))+" ")])]}},{key:"item.leave_from_date",fn:function(ref){
        var item = ref.item;
return [_c('span',{domProps:{"textContent":_vm._s(_vm.get_formate(item.leave_from_date))}}),_c('br'),_c('span',{domProps:{"textContent":_vm._s(_vm.get_formate(item.leave_to_date))}})]}},{key:"item.leave_to_date",fn:function(ref){
        var item = ref.item;
return [_c('span',{domProps:{"textContent":_vm._s(_vm.get_formate(item.leave_to_date))}})]}},{key:"item.leave_applied_on",fn:function(ref){
        var item = ref.item;
return [_c('span',{domProps:{"textContent":_vm._s(_vm.get_date(item.leave_applied_on))}})]}},{key:"item.approved_on",fn:function(ref){
        var item = ref.item;
return [(item.approved_on == undefined)?_c('span',[_vm._v("-")]):_c('span',{domProps:{"textContent":_vm._s(_vm.get_date(item.approved_on))}}),_c('br'),(item.approved_by == undefined)?_c('span',[_vm._v("-")]):_c('span',[_vm._v(_vm._s(item.approved_by))])]}},{key:"item.approved_by",fn:function(ref){
        var item = ref.item;
return [(item.approved_by == undefined)?_c('span',[_vm._v("-")]):_c('span',[_vm._v(_vm._s(item.approved_by))])]}},{key:"item.actionsstatus",fn:function(ref){
        var item = ref.item;
return [(item.leave_status == 'APPROVED')?_c('div',{staticClass:"green--text"},[_vm._v(" Approved ")]):(item.leave_status == 'PENDING')?_c('div',{staticClass:"orange--text"},[_vm._v(" Pending ")]):(item.leave_status == 'REJECTED')?_c('div',{staticClass:"red--text"},[_vm._v(" Rejected ")]):(item.leave_status == 'WITHDRAWN')?_c('div',{staticClass:"brown--text"},[_vm._v(" Withdrawn ")]):(item.leave_status == 'CANCELLATION_PENDING')?_c('div',{staticClass:"orange--text"},[_vm._v(" Cancellation Pending ")]):(item.leave_status == 'CANCELLATION_APPROVED')?_c('div',{staticClass:"green--text"},[_vm._v(" Cancellation Approved ")]):(item.leave_status == 'CANCELLATION_REJECTED')?_c('div',{staticClass:"red--text"},[_vm._v(" Cancellation Rejected ")]):_vm._e()]}},{key:"item.leave_status",fn:function(ref){
        var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-icon',_vm._g({directives:[{name:"show",rawName:"v-show",value:(item.leave_status == 'APPROVED'),expression:"item.leave_status == 'APPROVED'"}],staticStyle:{"color":"green"},attrs:{"small":"","depressed":"","medium":""}},on),[_vm._v(" mdi-account-check ")])]}}],null,true)},[_c('span',{staticClass:"white--text"},[_vm._v("APPROVED")])]),_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-icon',_vm._g({directives:[{name:"show",rawName:"v-show",value:(item.leave_status == 'CANCELLATION_REJECTED'),expression:"item.leave_status == 'CANCELLATION_REJECTED'"}],staticStyle:{"color":"red"},attrs:{"small":"","depressed":"","medium":""}},on),[_vm._v(" mdi-account-cancel ")])]}}],null,true)},[_c('span',{staticClass:"white--text"},[_vm._v("Cancellation Rejected")])]),_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-icon',_vm._g({directives:[{name:"show",rawName:"v-show",value:(item.leave_status == 'PENDING'),expression:"item.leave_status == 'PENDING'"}],staticStyle:{"color":"orange"},attrs:{"small":"","depressed":"","mediumapproved_on":""},on:{"click":function($event){return _vm.Approve(item)}}},on),[_vm._v(" mdi-account-alert ")])]}}],null,true)},[_c('span',{staticClass:"white--text"},[_vm._v("PENDING ")])]),_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-icon',_vm._g({directives:[{name:"show",rawName:"v-show",value:(item.leave_status == 'REJECTED'),expression:"item.leave_status == 'REJECTED'"}],staticStyle:{"color":"red"},attrs:{"small":"","depressed":"","medium":""}},on),[_vm._v(" mdi-account-cancel ")])]}}],null,true)},[_c('span',{staticClass:"white--text"},[_vm._v("REJECTED ")])]),_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-icon',_vm._g({directives:[{name:"show",rawName:"v-show",value:(item.leave_status == 'CANCELED'),expression:"item.leave_status == 'CANCELED'"}],staticStyle:{"color":"red"},attrs:{"small":"","depressed":"","medium":""}},on),[_vm._v(" mdi-cancel ")])]}}],null,true)},[_c('span',{staticClass:"white--text"},[_vm._v("CANCELED")])]),_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('div',_vm._g({directives:[{name:"show",rawName:"v-show",value:(item.leave_status == 'WITHDRAWN'),expression:"item.leave_status == 'WITHDRAWN'"}]},on),[_vm._v("-")])]}}],null,true)},[_c('span',{staticClass:"white--text"},[_vm._v("WITHDRAWN")])]),_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-icon',_vm._g({directives:[{name:"show",rawName:"v-show",value:(item.leave_status == 'CANCELLATION_PENDING'),expression:"item.leave_status == 'CANCELLATION_PENDING'"}],staticStyle:{"color":"orange"},attrs:{"small":"","depressed":"","mediumapproved_on":""},on:{"click":function($event){return _vm.Approve(item)}}},on),[_vm._v(" mdi-account-alert ")])]}}],null,true)},[_c('span',{staticClass:"white--text"},[_vm._v(" Cancellation Pending")])]),_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-icon',_vm._g({directives:[{name:"show",rawName:"v-show",value:(item.leave_status == 'CANCELLATION_APPROVED'),expression:"item.leave_status == 'CANCELLATION_APPROVED'"}],staticStyle:{"color":"green"},attrs:{"small":"","depressed":"","medium":""}},on),[_vm._v(" mdi-account-check ")])]}}],null,true)},[_c('span',{staticClass:"white--text"},[_vm._v("Approved Cancellation")])])]}},{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"small":"","depressed":"","color":"primary","medium":""},on:{"click":function($event){return _vm.commentsopen(item)}}},on),[_vm._v(" mdi-information-variant-circle-outline ")])]}}],null,true)},[_c('span',{staticClass:"white--text"},[_vm._v("Comments")])])]}}],null,true)})],1)],1),_c('SnackBar',{attrs:{"SnackBarComponent":_vm.SnackBarComponent}}),(_vm.componentcheck == 1)?_c('div',[_c('ApproveLeave',{attrs:{"approvaldta":_vm.approvaldta,"leaveApproveReject":_vm.leaveApproveReject},on:{"clicked":function($event){_vm.leaveApproveReject = false},"errorMsg":_vm.errorPop,"successMsg":_vm.sucessPop}})],1):_vm._e(),(_vm.componentcheck == 2)?_c('div',[_c('LeaveComments',{attrs:{"comLeave":_vm.comLeave,"approvaldta":_vm.approvaldta},on:{"clicked":function($event){_vm.comLeave = false}}})],1):_vm._e(),_c('Overlay',{attrs:{"overlay":_vm.overlay}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }