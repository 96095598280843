<template>
  <div>
    <v-dialog v-model="comLeave" persistent width="450px">
      <v-card class="overflow-hidden">
        <v-toolbar dense class="gradient-bg elevation-0">
          <v-toolbar-title class="white--text">Leave Comments</v-toolbar-title
          ><v-spacer />
          <v-icon @click="close_dialog()" class="white--text">mdi-close</v-icon>
        </v-toolbar>
        <v-card-text
          align="center"
          style="font-weight: bold; text-align: justify"
        >
          <v-textarea
            v-model="leavereason"
            height="100px"
            dense
            label="Reason For leave"
            class="mt-5"
            readonly
            outlined
          />
          <v-textarea
            v-if="approvaldta.approvals_comment != undefined"
            v-model="leavecomments"
            height="100px"
            dense
            label="Comments "
            class="mt-5"
            readonly
            outlined
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    comLeave: Boolean,
    approvaldta: Object,
  },
  data: () => ({
    leavereason: "",
    leavecomments: "",
  }),
  watch: {
    comLeave: {
      handler() {
        this.leavereason = this.approvaldta.leave_comments;
        this.leavecomments = this.approvaldta.approvals_comment;

        // console.log(this.approvaldta);
      },
      immediate: true,
    },
  },
  methods: {
    close_dialog() {
      this.$emit("clicked", 0);
    },
  },
};
</script>

<style>
</style>